import DocumentationPage from "@components/DocumentationPage";
import QuestionMarkTooltip from "@components/web/QuestionMarkTooltip";

export default () => {
	return <DocumentationPage>
		<h1>Inlined large</h1>
		<p style={{
			fontSize: 20
		}}>
			Some large inline text 
			<QuestionMarkTooltip text="Test" href="https://example.com" />
		</p>

		<h1>Inlined small</h1>
		<p style={{
			fontSize: 13
		}}>
			Some inline text 
			<QuestionMarkTooltip text="Test" href="https://example.com" />
		</p>

		<h1>Inlined large with href</h1>
		<p style={{
			fontSize: 20
		}}>
			Some large inline text 
			<QuestionMarkTooltip text="Test" href="https://example.com" />
		</p>

		<h1>Inlined small with href</h1>
		<p style={{
			fontSize: 13
		}}>
			Some inline text 
			<QuestionMarkTooltip text="Test" href="https://example.com" />
		</p>

		<h1>Single-line </h1>
		<QuestionMarkTooltip size={32} text="This is some help text spanning a single line." />

		<h1>Multi-line</h1>
		<QuestionMarkTooltip size={32} text={"This is some help text spanning\n\nmultiple lines."} />

		<h1>Href</h1>
		<QuestionMarkTooltip size={32} href="https://example.com" />
	</DocumentationPage>;
}