import ReshopperButton from "@components/web/Button";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';
import L from "@shared/i18n";
import { forwardRef, useState } from "react";
import styles from "./QuestionMarkTooltip.module.scss";

export type QuestionMarkTooltipProps = ({
	text: string,
	title?: string
} | {
	href: string
}) & {
	size?: number,
	light?: boolean
};

export default function QuestionMarkTooltip(props: QuestionMarkTooltipProps) {
	const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);

	const ImageElement = forwardRef((
		innerProps: {
			reactOnHover?: boolean
		}, 
		ref: any
	) => <HelpIcon 
		{...innerProps}
		className={`${styles.image} ${props.light ? styles.light : ""}`}
		ref={ref}
		onMouseOver={() => innerProps.reactOnHover && setIsDescriptionOpen(true)}
		onMouseLeave={() => innerProps.reactOnHover && setIsDescriptionOpen(false)}
		onClick={() => setIsDescriptionOpen(!isDescriptionOpen)} 
		style={{
			width: props.size,
			height: props.size
		}}
	/>);
	if("text" in props) {
		return <>
			<ImageElement />
			<Dialog 
				open={isDescriptionOpen}
				onClose={() => setIsDescriptionOpen(false)}
			>
				{props.title && <DialogTitle>{props.title}</DialogTitle>}
				<DialogContent>
					{props.text}
				</DialogContent>
				<DialogActions>
					<ReshopperButton 
						color="primary"
						variant="contained"
						onClick={() => setIsDescriptionOpen(false)}
					>
						{L("ok")}
					</ReshopperButton>
				</DialogActions>
			</Dialog>
		</>;
	} else {
		return <a 
			target="_blank" 
			href={props.href}
		>
			<ImageElement reactOnHover />
		</a>;
	}
}